import React, { FC } from 'react';

import { Col, Container, DangerouslySetInnerHtml, Layout, Row } from 'layout';

import { LdsPageProps } from './models';

const LdsPage: FC<LdsPageProps> = ({ pageContext }) => {
  const { body } = pageContext;

  return (
    <Layout>
      <section className="lds-page">
        <Container fluid>
          <Row>
            <Col lg="8">
              <DangerouslySetInnerHtml html={body} />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default LdsPage;
